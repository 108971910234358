<app-menu></app-menu>
    <div class="main-box">
        <img src="assets/detail-vector.png" class="right-image">
            <div class="d-flex flex-column gap-1 top-box">
                <a routerLink="/realisations" class="back-link">
                    <fa-icon class="back-link-icon" [icon]="faChevronLeft"></fa-icon>{{"MENU.BACK" | translate}}
                </a>
                <div class="top-box-text">
                    <div class="project-type">{{project?.type}}</div>
                    <div class="project-text">{{ project?.title }}</div>
                </div>
            </div>
            <div class="right-image"></div>
    </div>
    <div class="overlay-box d-flex flex-row gap-5">
        <div class="d-flex flex-column gap-3">
            <div class="subtitle">{{"PROJECT.COUNTRY" | translate}} <span class="subtitle-text">{{project?.country}}</span></div>
            <div class="subtitle">{{"PROJECT.CITY" | translate}} <span class="subtitle-text">{{project?.city}}</span></div>
        </div>
        <div class="d-flex flex-column gap-3">
            <div class="subtitle">Date: <span class="subtitle-text">{{project?.date}}</span></div>
            <div class="subtitle">{{"PROJECT.AMOUNT" | translate}}<span class="subtitle-text">{{project?.montant}}</span></div>
        </div>
        <div class="d-flex flex-column gap-3">
            <div class="subtitle">Client: <span class="subtitle-text">{{project?.client}}</span></div>
        </div>
    </div>

    <img class="project-image" [src]="'/assets/' + project.imageUrl">
    <div class="project-detail">
        <div class="project-detail-title">{{"PROJECT.PROGRAM" | translate}}</div>
            <div *ngFor="let program of project?.programs">
                <div>
                    <fa-icon class="project-detail-arrow" [icon]="faArrowRight"></fa-icon>
                    {{ program.program }}
                </div>
            </div>
        <div class="d-flex flex-row gap-3 image-detail-margin">
            <div *ngFor="let image of project?.additionalImages">
                <img [src]="'/assets/' + image" class="image-detail">
            </div>
        </div>
        <div class="project-detail-title">{{"PROJECT.MISSION" | translate}}</div>
        <div *ngFor="let mission of project?.missions">
            <div>
                <fa-icon class="project-detail-arrow" [icon]="faArrowRight"></fa-icon>
                {{ mission.mission }}
            </div>
        </div>
    </div>
