import { Component, OnInit } from '@angular/core';
import {faChevronLeft,faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../project.service";
import {Project} from "../project.model";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-projet-detail',
    templateUrl: './projet-detail.component.html',
    styleUrls: ['./projet-detail.component.scss']
})
export class ProjetDetailComponent implements OnInit {
    faChevronLeft = faChevronLeft;
    faArrowRight = faArrowRight
    project: any;

    constructor(private route: ActivatedRoute, private projectService: ProjectService) {}

    ngOnInit(): void {
        const projectIdString = this.route.snapshot.paramMap.get('id');
        if (projectIdString) {
            const projectId = +projectIdString;
            this.project = this.projectService.getProjectById(projectId);
        } else {
            // Handle case where 'id' parameter is null or undefined
            console.error('No project ID found in route parameters');
            // Optionally, redirect or handle the error appropriately
        }
    }
}
